import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isLoadingBrand: false,
  isLoadingWarehouse: false,
  isErrors: "",
  categoryList: [],
  subCategoryList: [],
  subUnitCategoryList: [],
  brandList: [],
  tagProductList: [],
  productList: [],
  skuSapList: [],
  paymentList: [],
  memberList: [],
  areaList: [],
  salesList: [],
  memberUnionList: [],
  salesUnionList: [],
  expeditionList: [],
  priceGroupList: [],
  nameAliasList: [],
  modulesList: [],
  modulesParentList: [],
  modulesSectionList: [],
  modulesTypeList: [],
  iconList: [],
  promoList: [],
  userGroupList: [],
  userList: [],
  emailTypeList: [],
  storeList: [],
  warehouseList: [],
  warehouseAreaList: [],
  warehouseTypeList: [],
  brandUnderList: [],
  departmentList: [],
  divisionList: [],
  levelList: [],
  companyList: [],
  ocrcList: [],
  ocrpList: [],
  loadCategory: false,
  loadSubCategory: false,
  loadSubUnitCategory: false,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOAD_CATEGORY(state, payload) {
    state.loadCategory = payload;
  },
  SET_LOAD_SUB_CATEGORY(state, payload) {
    state.loadSubCategory = payload;
  },
  SET_LOAD_SUB_UNIT_CATEGORY(state, payload) {
    state.loadSubUnitCategory = payload;
  },
  SET_LOADING_BRAND(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_WAREHOUSE(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  ASSIGN_CATEGORY_LIST(state, payload) {
    state.categoryList = payload;
  },
  ASSIGN_SUB_CATEGORY_LIST(state, payload) {
    state.subCategoryList = payload;
  },
  ASSIGN_SUB_UNIT_CATEGORY_LIST(state, payload) {
    state.subUnitCategoryList = payload;
  },
  ASSIGN_BRAND_LIST(state, payload) {
    state.brandList = payload;
  },
  ASSIGN_TAG_PRODUCT_LIST(state, payload) {
    state.tagProductList = payload;
  },
  ASSIGN_PRODUCT_LIST(state, payload) {
    state.productList = payload;
  },
  ASSIGN_SKU_SAP_LIST(state, payload) {
    state.skuSapList = payload;
  },
  ASSIGN_PAYMENT_LIST(state, payload) {
    state.paymentList = payload;
  },
  ASSIGN_MEMBER_LIST(state, payload) {
    state.memberList = payload;
  },
  ASSIGN_AREA_LIST(state, payload) {
    state.areaList = payload;
  },
  ASSIGN_SALES_LIST(state, payload) {
    state.salesList = payload;
  },
  ASSIGN_SALES_UNION_LIST(state, payload) {
    state.salesUnionList = payload;
  },
  ASSIGN_MEMBER_UNION_LIST(state, payload) {
    state.memberUnionList = payload;
  },
  ASSIGN_EXPEDITION_LIST(state, payload) {
    state.expeditionList = payload;
  },
  ASSIGN_GROUP_LIST(state, payload) {
    state.priceGroupList = payload;
  },
  ASSIGN_NAME_ALIAS(state, payload) {
    state.nameAliasList = payload;
  },
  ASSIGN_MODULES_LIST(state, payload) {
    state.modulesList = payload;
  },
  ASSIGN_MODULES_PARENT_LIST(state, payload) {
    state.modulesParentList = payload;
  },
  ASSIGN_MODULES_SECTION_LIST(state, payload) {
    state.modulesSectionList = payload;
  },
  ASSIGN_MODULES_TYPE_LIST(state, payload) {
    state.modulesTypeList = payload;
  },
  ASSIGN_ICON_LIST(state, payload) {
    state.iconList = payload;
  },
  ASSIGN_PROMO_LIST(state, payload) {
    state.promoList = payload;
  },
  ASSIGN_USER_GROUP_LIST(state, payload) {
    state.userGroupList = payload;
  },
  ASSIGN_USER_LIST(state, payload) {
    state.userList = payload;
  },
  ASSIGN_EMAIL_TYPE_LIST(state, payload) {
    state.emailTypeList = payload;
  },
  ASSIGN_STORE_LIST(state, payload) {
    state.storeList = payload;
  },
  ASSIGN_WAREHOUSE_LIST(state, payload) {
    state.warehouseList = payload;
  },
  ASSIGN_WAREHOUSE_AREA_LIST(state, payload) {
    state.warehouseAreaList = payload;
  },
  ASSIGN_WAREHOUSE_TYPE_LIST(state, payload) {
    state.warehouseTypeList = payload;
  },
  ASSIGN_BRAND_UNDER(state, payload) {
    state.brandUnderList = payload;
  },
  ASSIGN_DEPARTMENT(state, payload) {
    state.departmentList = payload;
  },
  ASSIGN_DIVISION(state, payload) {
    state.divisionList = payload;
  },
  ASSIGN_LEVEL(state, payload) {
    state.levelList = payload;
  },
  ASSIGN_COMPANY(state, payload) {
    state.companyList = payload;
  },
  ASSIGN_OCRP(state, payload) {
    state.ocrpList = payload;
  },
  ASSIGN_OCRC(state, payload) {
    state.ocrcList = payload;
  },
};

const actions = {
  selectIcons({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/icon?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_ICON_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectCategory({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_LOAD_CATEGORY", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/category${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_CATEGORY_LIST", res.data.data);
          commit("SET_LOAD_CATEGORY", false);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSubCategory({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_LOAD_SUB_CATEGORY", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/subcategory${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_SUB_CATEGORY_LIST", res.data.subcategory);
          commit("SET_LOADING", false);
          commit("SET_LOAD_SUB_CATEGORY", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSubUnitCategory({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_LOAD_SUB_UNIT_CATEGORY", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/unit${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_SUB_UNIT_CATEGORY_LIST", res.data.data);
          commit("SET_LOADING", false);
          commit("SET_LOAD_SUB_UNIT_CATEGORY", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectBrand({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_BRAND", true);
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      if (payload.find) {
        params.append('find', payload.find);
      }
      if (payload.under) {
        params.append('under', payload.under);
      }

      $axios
        .get(`select/brand?${params.toString()}`)
        .then((res) => {
          commit("ASSIGN_BRAND_LIST", res.data.data);
          commit("SET_LOADING_BRAND", false);
        })
        .catch((error) => {
          commit("SET_LOADING_BRAND", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectTagProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/tag?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_TAG_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/product?product_name=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectProductWeb({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/product-web?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSKUSap({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/skusap?searchname=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_SKU_SAP_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectPayment({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/payment${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_PAYMENT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/member?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MEMBER_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectArea({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/cityprovince?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_AREA_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSales({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/sales?name=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_SALES_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSalesUnion({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/sales-union?sales_code=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_SALES_UNION_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectMemberUnion({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/member-union?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MEMBER_UNION_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectExpedition({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/shipping${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_EXPEDITION_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectPriceGroup({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/pricegroup${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_GROUP_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectNameAlias({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/namealias?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_NAME_ALIAS", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectname({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/module?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MODULES_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectParentModule({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/moduleparent?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MODULES_PARENT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectSectionModule({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/modulesection?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MODULES_SECTION_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectTypeModule({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/moduletype?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_MODULES_TYPE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectActivePromo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`select/activepromo`, payload)
        .then((res) => {
          commit("ASSIGN_PROMO_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectUserGroup({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/pricegroup?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_USER_GROUP_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectUser({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/user?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_USER_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectEmailType({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/email-type?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_EMAIL_TYPE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectStore({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`dashboardsync/offline-store/select-store?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_STORE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectStoreWeb({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/store?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_STORE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectWarehouse({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_WAREHOUSE", true);
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      if (payload.find) {
        params.append('find', payload.find);
      }
      if (payload.area) {
        params.append('area', payload.area);
      }
      if (payload.type) {
        params.append('type', payload.type);
      }
      $axios
        .get(`select/warehouse?${params.toString()}`)
        .then((res) => {
          commit("ASSIGN_WAREHOUSE_LIST", res.data.data);
          commit("SET_LOADING_WAREHOUSE", false);
        })
        .catch((error) => {
          commit("SET_LOADING_WAREHOUSE", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectWarehouseArea({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/warehouse-area?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_WAREHOUSE_AREA_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectWarehouseType({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/warehouse-type?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_WAREHOUSE_TYPE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectBrandUnder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/brand-under?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_BRAND_UNDER", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectDepartment({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/department?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_DEPARTMENT", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectDivision({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/division?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_DIVISION", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectLevel({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/level?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_LEVEL", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectCompany({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/company?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_COMPANY", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectOcrpPaymentType({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/ocrp-payment-type?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_OCRP", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  selectOcrcPaymentChannel({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/ocrc-payment-channel?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_OCRC", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  selectProductTiktok({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/product-free-item?product_name=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

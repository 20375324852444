import administrator from "./administrator";
import reseller from "./reseller";
import brand from "./brand";
import website from "./website";
import inventory from "./inv";
import store_location from "./store-location";
import hr from "./hr";
import server from "./server";
import tiktok from "./tiktok";
import dashboard from "./dashboard";

export default [
  /** GLOBAL ROUTES */
  { path: "*", component: () => import("../pages/global/error/404.vue") },
  {
    path: "/403",
    component: () => import("../pages/global/error/403.vue"),
  },
  // ================ Import External Route ================
  ...administrator,
  ...reseller,
  ...brand,
  ...website,
  ...inventory,
  ...store_location,
  ...hr,
  ...server,
  ...tiktok,
  ...dashboard,
  {
    name: "profile_index",
    path: "/profile",
    component: () => import("../pages/global/profile/Profile.vue"),
  },

  {
    name: "devperformance_index",
    path: "/server/devperformance",
    component: () => import("../pages/server/dev-performance/index.vue"),
  },
];

export default [
  {
    name: "stocki_index",
    path: "/inv/stock",
    component: () => import("../pages/inv/stock/index.vue"),
  },
  {
    name: "stocki_log",
    path: "/inv/log-stock",
    component: () => import("../pages/inv/stockLog/index.vue"),
  },
  // {
  //   name: "stocki_in",
  //   path: "/inv/stock-in",
  //   component: () => import("../pages/inv/stockIn/index.vue"),
  // },
  {
    name: "stocki_out",
    path: "/inv/stock-out",
    component: () => import("../pages/inv/stockOut/index.vue"),
  },
  {
    name: "transferi_create",
    path: "/inv/transfer-stock",
    component: () => import("../pages/inv/transferStock/index.vue"),
  },
  // {
  //   name: "stocki_in",
  //   path: "/inv/add-product-code",
  //   component: () => import("../pages/inv/addCodeProduct/index.vue"),
  // },
];

export default [
  // ================== Attendance ==================
  {
    name: "attendance_convert",
    path: "/hr/attendance",
    component: () => import("../pages/hr/attendance/convert/index.vue")
  },
  // ================== HRIS =====================
  {
    name: "attendance_status",
    path: "/hr/attendance/status",
    component: () => import("../pages/hr/attendance/status/index.vue")
  },
  // ================== Recruitment =================
  {
    name: "recruitment_list",
    path: "/hr/recruitment",
    component: () => import("../pages/hr/recruitment/index.vue")
  },
  // ================== Master Data =================
  {
    name: "employee_index",
    path: "/hr/employee",
    component: () => import("../pages/hr/employee/index.vue"),
  },
  {
    name: "employee_create",
    path: "/hr/employee/create/:id",
    component: () => import("../pages/hr/employee/manage.vue"),
  },
  {
    name: "employee_update",
    path: "/hr/employee/update/:id",
    component: () => import("../pages/hr/employee/manage.vue"),
  },
  {
    name: "recruitment_exportemployee",
    path: "/hr/recruitment/exportemployee/:code",
    component: () => import("../pages/hr/recruitment/details.vue")
  },
  {
    name: "division_index",
    path: "/hr/division",
    component: () => import("../pages/hr/division/index.vue"),
  },
  {
    name: "department_index",
    path: "/hr/department",
    component: () => import("../pages/hr/department/index.vue"),
  },
  {
    name: "level_index",
    path: "/hr/level",
    component: () => import("../pages/hr/level/index.vue"),
  },
  // ================== Careers =====================
  {
    name: "cpage_homepage",
    path: "/hr/homepage",
    component: () => import("../pages/career/homepage/index")
  },
  {
    name: "cpage_create",
    path: "/hr/homepage/create",
    component: () => import("../pages/career/homepage/manage")
  },
  {
    name: "cpage_contactus",
    path: "/hr/contactus",
    component: () => import("../pages/career/contactus/index")
  },
  {
    name: "cpage_career",
    path: "/hr/career",
    component: () => import("../pages/career/career/index")
  },
  {
    name: "cpage_update",
    path: "/hr/homepage/update/:id",
    component: () => import("../pages/career/homepage/manage")
  },
  {
    name: "cbanner_list",
    path: "/hr/banner",
    component: () => import("../pages/career/banner/index")
  },
  {
    name: "cbanner_create",
    path: "/hr/banner/create",
    component: () => import("../pages/career/banner/create")
  },
  {
    name: "cbanner_update",
    path: "/hr/banner/update/:id",
    component: () => import("../pages/career/banner/update")
  },
  {
    name: "cjob_list",
    path: "/hr/jobs",
    component: () => import("../pages/career/jobs/index")
  },
  {
    name: "cjob_create",
    path: "/hr/jobs/create",
    component: () => import("../pages/career/jobs/create")
  },
  {
    name: "cjob_update",
    path: "/hr/jobs/update/:id",
    component: () => import("../pages/career/jobs/update")
  },
  {
    name: "capplyjob_list",
    path: "/hr/applyjob",
    component: () => import("../pages/career/applyjob/index")
  },
  {
    name: "capplyjob_view",
    path: "/hr/applyjob/view/:id",
    component: () => import("../pages/career/applyjob/view")
  },

];

export default [
    // ================ tiktok ================
    {
        name: "ordertiktok_index",
        path: "/tiktok/order",
        component: () => import("../pages/tiktok/order/index.vue"),
    },
    {
        name: "categorytiktok_index",
        path: "/tiktok/category",
        component: () => import("../pages/tiktok/category/index.vue"),
    },
    {
        name: "categorytiktok_create",
        path: "/tiktok/category/create",
        component: () => import("../pages/tiktok/category/manage.vue"),
    },
    {
        name: "categorytiktok_update",
        path: "/tiktok/category/update/:id",
        component: () => import("../pages/tiktok/category/manage.vue"),
    },
    {
        name: "assigncategory_index",
        path: "/tiktok/assign-category",
        component: () => import("../pages/tiktok/assignCategory/index.vue"),
    },
    {
        name: "dashtiktok_member",
        path: "/tiktok/member/:id",
        component: () => import("../pages/homepage/tiktok/performanceMemberDetail.vue"),
    },
];

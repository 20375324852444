import $axios from "../../../api";
import FileSaver from "file-saver";

// MODULE NAME
const module = "tiktok/orders";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
  orderSummary: 0,
  dataOrderImport: null,

  isLoadingDetail: true,
  detailItemOrder: null,

  loadingCategory: true,
  categoryList: [],

  orderStatusList: [
    {
      id: 1,
      label: "Confirm",
    },
    {
      id: 2,
      label: "Pending",
    },
    {
      id: 3,
      label: "Cancel",
    },
  ],

});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_DETAIL(state, payload) {
    state.isLoadingDetail = payload;
  },
  SET_LOADING_CATEGORY(state, payload) {
    state.loadingCategory = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  ASSIGN_ORDER_SUMMARY(state, payload) {
    state.orderSummary = payload;
  },
  ASSIGN_DATA_ORDER_IMPORT(state, payload) {
    state.dataOrderImport = payload;
  },
  ASSIGN_DETAIL_ITEM_ORDER(state, payload) {
    state.detailItemOrder = payload;
  },
  ASSIGN_CATEGORY_LIST(state, payload) {
    state.categoryList = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("ASSIGN_ORDER_SUMMARY", res.data.summary);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  importOrderExcel({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("ASSIGN_ERRORS", "");

    var formData = new FormData();
    formData.append("file", payload.file);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/import`, formData)
        .then((res) => {
            commit("ASSIGN_DATA_ORDER_IMPORT", res.data.status);
            commit("SET_LOADING", false);
            resolve(res);
        })
        .catch((error) => {
            commit("SET_LOADING", false);
            commit("ASSIGN_ERRORS", error.response);
            dispatch("Main/globalSweetAlert", error.response, {
                root: true,
            });
            reject(error);
        });
    });
  },

  exportData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/export?view=${rootState.Main.view}${payload || ""
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileSaver.saveAs(
            res.data,
            `Order_view=${rootState.Main.view}${payload || ""
            }_${new Date()}.xlsx`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  confirmStockTiktok({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/confirm`, {no_order: payload})
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          if(error.response.status == 400){
            if(error.response.data?.data){
                const responseError = error.response.data.data;
                let htmlContent = '';
                htmlContent += `<p class="font-size-120rem mb-4 font-weight-bold">Stok tidak mencukupi harap periksa kembali</p>`
                htmlContent += `
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="font-size-080rem">Product</th>
                                <th scope="col" class="font-size-080rem">SKU</th>
                                <th scope="col" class="font-size-080rem">Stock</th>
                            </tr>
                        </thead>
                        <tbody>`
                responseError.forEach(item => {
                    htmlContent += `
                            <tr>
                                <td class="font-size-080rem text-left">${item.product_name}</td>
                                <td class="font-size-080rem">${item.sku}</td>
                                <td class="font-size-080rem">${item.stock}</td>
                            </tr>
                    `
                });
                htmlContent += `
                        </tbody>
                </table>`

                Swal.fire({
                    icon: "warning",
                    html: htmlContent,
                });
            }else{
                dispatch("Main/globalSweetAlert", error.response, {
                    root: true,
                });
            }
          } else{
            dispatch("Main/globalSweetAlert", error.response, {
                root: true,
            });
          }
        });
    });
  },

  deleteOrderTiktok({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/cancel`, {no_order: payload})
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  deleteProductOrderTiktok({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/detail-cancel`, {
            id: payload.id,
            no_order: payload.no_order,
            sku: payload.sku,
        })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  listCategory({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_CATEGORY", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/category-tiktok?name=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_CATEGORY_LIST", res.data.data);
          commit("SET_LOADING_CATEGORY", false);
        })
        .catch((error) => {
          commit("SET_LOADING_CATEGORY", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  getDetailItem({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
            `${module}/detail?no_order=${payload.no_order}&sku=${payload.sku}`
        )
        .then((res) => {
            commit("ASSIGN_DETAIL_ITEM_ORDER", res.data.data);
            commit("SET_LOADING_DETAIL", false);
            resolve(res);
        })
        .catch((error) => {
            commit("SET_LOADING_DETAIL", false);
            dispatch("Main/globalSweetAlert", error.response, {
                root: true,
            });
        });
    });
  },

  saveEditOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  savePhoneNumber({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/update-data`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },

  addProductItemTiktok({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

import $axios from "../../api";

const module = "recruitment"
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const state = () => ({
  isLoading: false,
  isLoadingGenerate: false,
  isLoadingSelect: false,
  isLoadingGet: false,
  isSuccess: false,
  isErrors: "",
  candidateList: [],
  employeeView: [],
  candidateKtp: [],
  summary: [],
  positionList: [],
  linkToken: null,
  page: 1,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_GENERATE(state, payload) {
    state.isLoadingGenerate = payload;
  },
  SET_LOADING_GET(state, payload) {
    state.isLoadingGet = payload;
  },
  SET_LOADING_SELECT(state, payload) {
    state.isLoadingSelect = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_CANDIDATE_LIST(state, payload) {
    state.candidateList = payload;
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload;
  },
  SET_EMPLOYEE_VIEW(state, payload) {
    state.employeeView = payload;
  },
  SET_CANDIDATE_KTP(state, payload) {
    state.candidateKtp = payload;
  },
  ASSIGN_POSITION_LIST(state, payload) {
    state.positionList = payload;
  },
  ASSIGN_LINK_TOKEN(state, payload) {
    state.linkToken = payload;
  },
};

const actions = {
  getCandidateList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/list?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_CANDIDATE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getSummary({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/summary?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_SUMMARY", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getSummaryDashboard({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/summary-dashboard`
        )
        .then((res) => {
          commit("SET_SUMMARY", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getSelectPosition({ commit, dispatch }, payload) {
    commit("SET_LOADING_SELECT", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/select/position?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_POSITION_LIST", res.data.data);
          commit("SET_LOADING_SELECT", false);
        })
        .catch((error) => {
          commit("SET_LOADING_SELECT", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  generateLinkToken({ commit, dispatch }) {
    commit("SET_LOADING_GENERATE", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/generate-link-token`)
        .then((res) => {
          commit("ASSIGN_LINK_TOKEN", res.data.data);
          commit("SET_LOADING_GENERATE", false);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("SET_LOADING_GENERATE", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
          reject(error);
        });
    });
  },
  generateCandidate({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/generate-candidate`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSuccessSweetAlert", "New link and token have been successfully generated! ", {
            root: true,
          });
          resolve(response);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  generateEmployee({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/generate-employee`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSuccessSweetAlert", "New link and token have been successfully generated! ", {
            root: true,
          });
          resolve(response);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  changeStatus({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/change-status`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSuccessSweetAlert", "Status has changed to " + payload.to_status, { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  getEmployeeData({ commit, dispatch }, payload) {
    commit("SET_LOADING_GET", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/employee/view`, { params: payload })
        .then((res) => {
          commit("SET_EMPLOYEE_VIEW", res.data.data);
          commit("SET_LOADING_GET", false);
          resolve(res.data);
        })
        .catch((error) => {
          commit("SET_LOADING_GET", false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
          reject(error);
        });
    });
  },
  getEmployeeCopy({ commit, dispatch }, payload) {
    commit("SET_LOADING_GET", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/employee/copy`, { params: payload })
        .then((res) => {
          commit("SET_EMPLOYEE_VIEW", res.data.data);
          commit("SET_LOADING_GET", false);
          resolve(res.data);
        })
        .catch((error) => {
          commit("SET_LOADING_GET", false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
          reject(error);
        });
    });
  },
  candidateViewBy({ commit, dispatch }, payload) {
    commit("SET_LOADING_GET", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`candidate/view-by`, { params: payload })
        .then((res) => {
          commit("SET_CANDIDATE_KTP", res.data.data);
          commit("SET_LOADING_GET", false);
          resolve(res.data);
        })
        .catch((error) => {
          commit("SET_LOADING_GET", false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};


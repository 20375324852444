export default [
  {
    name: "orderstore_index",
    path: "/offline-stores/stats",
    component: () => import("../pages/global/syncunion/statistics.vue"),
  },

  // ================ Stock ================
  {
    name: "stockobts_index",
    path: "/inv/stock-obts",
    component: () => import("../pages/union/stock/index.vue"),
  },
  {
    name: "stockobts_log",
    path: "/inv/stock-obts/log",
    component: () => import("../pages/union/stock/logStock.vue"),
  },

  // ================ Offline Stores ================
  {
    name: "promobank_list",
    path: "/offline-stores/promo-bank",
    component: () => import("../pages/union/promo-bank/index.vue"),
  },
  {
    name: "promobank_create",
    path: "/offline-stores/promo-bank/create",
    component: () => import("../pages/union/promo-bank/manage.vue"),
  },
  {
    name: "promobank_update",
    path: "/offline-stores/promo-bank/update/:id",
    component: () => import("../pages/union/promo-bank/manage.vue"),
  },
  /** ================================ */ 
  {
    name: "tokovouchers_list",
    path: "/offline-stores/vouchers",
    component: () => import("../pages/union/vouchers/index.vue"),
  },
  {
    name: "tokovouchers_update",
    path: "/offline-stores/vouchers/update/:id",
    component: () => import("../pages/union/vouchers/update.vue"),
  },
  /** ================================ */ 
];



import $axios from "../../api";

const PREFIX = "website/dashboard";
const PREFIX_3 = "website/dash/3";

const state = () => ({
  isLoading: false,
  isLoadingDetail: false,
  isSuccess: false,
  errors: "",
  page: 1,
  pageDetail: 1,
  //Tier1
  dashboardData: [],
  //Tier2
  performProductData: [],
  topCategoryData: [],
  topCategoryOverview: [],
  topBrandData: [],
  topBrandOverview: [],
  topMemberData: [],
  topMemberOverview: [],
  InActiveMemberData: [],
  InActiveMemberOverview: [],
  topVoucherData: [],
  topVoucherOverview: [],
  topPaymentData: [],
  topPaymentOverview: [],
  outOffStockData: [],
  almostOutStockData: [],
  managementMemberData: [],
  //Tier 3
  performVisitDetail: [],
  performCartDetail: [],
  performOrderDetail: [],
  topCategoryDetail: [],
  topBrandDetail: [],
  topVoucherDetail: [],
  topPaymentDetail: [],
  memberDetail: [],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_DETAIL(state, payload) {
    state.isLoadingDetail = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_PAGE_DETAIL(state, payload) {
    state.pageDetail = payload;
  },
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  SET_PERFORMPRODUCT_DATA(state, payload) {
    state.performProductData = payload;
  },
  SET_TOPPAYMENT_DATA(state, payload) {
    state.topPaymentData = payload;
  },
  SET_TOPCATEGORY_DATA(state, payload) {
    state.topCategoryData = payload;
  },
  SET_TOPCATEGORY_OVERVIEW(state, payload) {
    state.topCategoryOverview = payload;
  },
  SET_TOPBRAND_DATA(state, payload) {
    state.topBrandData = payload;
  },
  SET_TOPBRAND_OVERVIEW(state, payload) {
    state.topBrandOverview = payload;
  },
  SET_TOPMEMBER_DATA(state, payload) {
    state.topMemberData = payload;
  },
  SET_TOPMEMBER_OVERVIEW(state, payload) {
    state.topMemberOverview = payload;
  },
  SET_INACTIVEMEMBER_DATA(state, payload) {
    state.InActiveMemberData = payload;
  },
  SET_INACTIVEMEMBER_OVERVIEW(state, payload) {
    state.InActiveMemberOverview = payload;
  },
  SET_TOPVOUCHER_DATA(state, payload) {
    state.topVoucherData = payload;
  },
  SET_TOPVOUCHER_OVERVIEW(state, payload) {
    state.topVoucherOverview = payload;
  },
  SET_TOPPAYMENT_DATA(state, payload) {
    state.topPaymentData = payload;
  },
  SET_TOPPAYMENT_OVERVIEW(state, payload) {
    state.topPaymentOverview = payload;
  },
  SET_OUTOFFSTOCK_DATA(state, payload) {
    state.outOffStockData = payload;
  },
  SET_ALMOSTOUTSTOCK_DATA(state, payload) {
    state.almostOutStockData = payload;
  },
  SET_MANAGEMENTMEMBER_DATA(state, payload) {
    state.managementMemberData = payload;
  },
  SET_PERFORMVISIT_DETAIL(state, payload) {
    state.performVisitDetail = payload;
  },
  SET_PERFORMCART_DETAIL(state, payload) {
    state.performCartDetail = payload;
  },
  SET_PERFORMORDER_DETAIL(state, payload) {
    state.performOrderDetail = payload;
  },
  SET_TOPCATEGORY_DETAIL(state, payload) {
    state.topCategoryDetail = payload;
  },
  SET_TOPBRAND_DETAIL(state, payload) {
    state.topBrandDetail = payload;
  },
  SET_TOPVOUCHER_DETAIL(state, payload) {
    state.topVoucherDetail = payload;
  },
  SET_TOPPAYMENT_DETAIL(state, payload) {
    state.topPaymentDetail = payload;
  },
  SET_MEMBER_DETAIL(state, payload) {
    state.memberDetail = payload;
  },
};

const actions = {
  getDashboardData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/list?${payload || ""}`
        )
        .then((res) => {
          commit("SET_DASHBOARD_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/conversion-rate?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMPRODUCT_DATA", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopCategoryData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/top-category?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPCATEGORY_DATA", res.data.data);
          commit("SET_TOPCATEGORY_OVERVIEW", res.data.top_product);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopBrandData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/top-brand?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPBRAND_DATA", res.data.data);
          commit("SET_TOPBRAND_OVERVIEW", res.data.top_product);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/top-member?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPMEMBER_DATA", res.data.data);
          commit("SET_TOPMEMBER_OVERVIEW", {
            countmember: res.data.countmember,
            countnonmember: res.data.countnonmember,
            totalmember: res.data.totalmember,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getInactiveMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/member-inactive?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_INACTIVEMEMBER_DATA", res.data.data);
          commit("SET_INACTIVEMEMBER_OVERVIEW", {
            countmember: res.data.countmember,
            countnonmember: res.data.countnonmember,
            totalmember: res.data.totalmember,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopVoucherData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/top-voucher?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPVOUCHER_DATA", res.data.data);
          commit("SET_TOPVOUCHER_OVERVIEW", {
            count_order: res.data.count_order,
            count_member: res.data.count_member,
            count_transaction: res.data.count_transaction,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopPaymentData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/top-payment?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          const dataList = res.data.data;
          commit("SET_TOPPAYMENT_DATA", dataList);
          commit("SET_TOPPAYMENT_OVERVIEW", dataList.data.slice(0, 6));
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getOutOfStockData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/out-of-stock?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_OUTOFFSTOCK_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getAlmostOutStockData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/almost-out-stock?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_ALMOSTOUTSTOCK_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getManagementMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/member-process?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_MANAGEMENTMEMBER_DATA", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceVisitDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-visit?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMVISIT_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceCartDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-cart?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMCART_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceOrderDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-orders?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMORDER_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopCategoryDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/detail-top-category?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPCATEGORY_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopBrandDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/detail-top-brand?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPBRAND_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopVoucherDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX}/detail-top-voucher?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPVOUCHER_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopPaymentDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/payment?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPPAYMENT_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getMemberDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `website/member/view?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_MEMBER_DETAIL", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

window.Vue = require("vue");
import Vuex from "vuex";

//import Global Modules Vuex
import Main from "./stores/global/Main";
import User from "./stores/global/User";
import Sidebar from "./stores/global/Sidebar";
import Roles from "./stores/global/Roles";
import Module from "./stores/global/Module";
import Profile from "./stores/global/Profile";
import Select from "./stores/global/Select";
import Product from "./stores/global/Product";
import Member from "./stores/global/Member";
import SyncUnion from "./stores/global/SyncUnion";
import Gallery from "./stores/global/Gallery";
import Global from "./stores/global/Global";

//import Reseller Modules Vuex
import Order from "./stores/reseller/Order";
import Dashboard from "./stores/reseller/Dashboard";

//Promotions
import Promo from "./stores/reseller/Promotions/Promo";
import PromoPrice from "./stores/reseller/Promotions/PromoPrice";
import PromoPercent from "./stores/reseller/Promotions/PromoPercent";
import PromoPercentDouble from "./stores/reseller/Promotions/PromoPercentDouble";
import PromoBuyGet from "./stores/reseller/Promotions/PromoBuyGet";
import PromoMinBuyGet from "./stores/reseller/Promotions/PromoMinBuyGet";
import PromoMinBuyGetMix from "./stores/reseller/Promotions/PromoMinBuyGetMix";
import PromoMinBuyGetMixDiscPrice from "./stores/reseller/Promotions/PromoMinBuyGetMixDiscPrice";
import PriceGroup from "./stores/reseller/PriceGroup";
import UserPriceGroup from "./stores/reseller/UserPriceGroup";

//import Website Modules Vuex
import Brand from "./stores/website/Brand";
// import DashboardW from "./stores/website/Dashboard";
import Store from "./stores/website/Store";
import MemberW from "./stores/website/Member";
import PromoBadge from "./stores/website/promoBadge";
import Email from "./stores/website/Email";
import KeywordTag from "./stores/website/KeywordTag";
import WebOrder from "./stores/website/Orders";
import ProductWeb from "./stores/website/ProductWeb";
import ShippingWeb from "./stores/website/Shipping";
import TrackAnteraja from "./stores/website/tracking/Anteraja";
import TrackGosend from "./stores/website/tracking/Gosend";
import TrackLalamove from "./stores/website/tracking/Lalamove";
import RefundWeb from "./stores/website/Refund";
import Subscription from "./stores/website/Subscription";

// import Union Modules Vuex
import UnionStock from "./stores/union/UnionStock";
import OfflineStores from "./stores/union/OfflineStores";

// Synchronize
import StoreStatistics from "./stores/global/StoreStatistics";
import DashboardExternal from "./stores/website/DashboardExternal";

// ORDER STORE
import OrderStores from "./stores/union/OrderStores";

// CAREER PAGE
import PageContent from "./stores/career/PageContent";
import Banner from "./stores/career/Banner";
import Career from "./stores/career/Career";
import ApplyJob from "./stores/career/ApplyJob";

// INVENTORY PAGE
import Stock from "./stores/inv/Stock";
import Allocation from "./stores/inv/Allocation";
import SelectInv from "./stores/inv/SelectInv";
import TransferInv from "./stores/inv/TransferInv";
import Warehouse from "./stores/inv/Warehouse";

// Human Resource Page
import DashboardCareer from "./stores/hr/DashboardCareer";
import Attendance from "./stores/hr/Attendance";
import Recruitment from "./stores/hr/Recruitment";
import Employee from "./stores/hr/Employee";
import Department from "./stores/hr/Department";
import Division from "./stores/hr/Division";
import Level from "./stores/hr/Level";

// Server Resource Page
import UserServer from "./stores/server/UserServer";
import VsCode from "./stores/server/VsCode";

// App
// import DashboardApp from "./stores/app/Dashboard";

// Tiktok
import Tiktok from "./stores/tiktok/order/Tiktok";
import CategoryTiktok from "./stores/tiktok/category/CategoryTiktok";

import DashboardStats from "./stores/website/DashboardStats";
import DashboardWebsite from "./stores/website/Dashboard";
import DashboardApp from "./stores/app/Dashboard";
import DashboardTiktok from "./stores/tiktok/Dashboard";

const store = new Vuex.Store({
  modules: {
    DashboardStats,
    DashboardWebsite,
    DashboardApp,
    DashboardTiktok,
    RefundWeb,
    TrackAnteraja,
    TrackGosend,
    TrackLalamove,
    ShippingWeb,
    ProductWeb,
    Stock,
    Global,
    KeywordTag,
    Banner,
    Email,
    Dashboard,
    Gallery,
    Main,
    Member,
    Module,
    User,
    Order,
    Profile,
    Product,
    Roles,
    Sidebar,
    SyncUnion,
    Select,
    Warehouse,
    PromoPrice,
    PromoPercent,
    PromoPercentDouble,
    PromoBuyGet,
    PromoMinBuyGet,
    PromoMinBuyGetMix,
    PromoMinBuyGetMixDiscPrice,
    PageContent,
    Promo,
    Banner,
    Career,
    ApplyJob,
    PriceGroup,
    UserPriceGroup,
    Brand,
    Store,
    MemberW,
    PromoBadge,
    // DashboardW,
    Allocation,
    SelectInv,
    TransferInv,
    WebOrder,
    UnionStock,
    StoreStatistics,
    DashboardExternal,
    Attendance,
    Recruitment,
    Employee,
    Department,
    Division,
    Level,
    OfflineStores,
    DashboardCareer,
    OfflineStores,
    UserServer,
    OrderStores,
    Tiktok,
    CategoryTiktok,
    VsCode,
    Subscription,
  },
});


export default store;

// Import semua modul secara dinamis
// const requireModule = require.context(
//   "./stores",
//   true,
//   /^(.*\.(js$))[^.]*$/i
// );

// const modules = requireModule
//   .keys()
//   .reduce((modules, modulePath) => {
//     const moduleName = modulePath
//       .replace(/^\.\/(.*)\.\w+$/, "$1")
//       .split("/")
//       .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
//       .join("");

//     const module = requireModule(modulePath);
//     modules[moduleName] = module.default || module;
//     return modules;
//   }, {});

// const store = new Vuex.Store({
//   modules,
// });

// export default store;

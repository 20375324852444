import $axios from "../../api";

const PREFIX_1 = "app/dash/1";
const PREFIX_2 = "app/dash/2";
const PREFIX_3 = "app/dash/3";

const state = () => ({
  isLoading: false,
  isLoadingDetail: false,
  isLoadingTable: false,
  isSuccess: false,
  errors: "",
  page: 1,
  pageDetail: 1,
  // ========== Tier 1 ========== //
  dashboardData: [],
  // ======== End Tier 1 ======== //

  // ========== Tier 2 ========== //
  // Performance Product
  performRateData: [],
  performVisitData: [],
  performCartData: [],
  performOrdersData: [],
  // Tren Penjualan
  topCategoryData: [],
  topCategoryOverview: [],
  topBrandData: [],
  topBrandOverview: [],
  // Aktivitas Pembelian
  topMemberData: [],
  topMemberOverview: [],
  InActiveMemberData: [],
  InActiveMemberOverview: [],
  // Promosi 
  topVoucherData: [],
  topVoucherOverview: [],
  // Metode Pembayaran
  topPaymentData: [],
  topPaymentOverview: [],
  // Metode Pembayaran
  outOffStockData: [],
  almostOutStockData: [],
  // Manajemen Member
  managementMemberData: [],
  // ======== End Tier 2 ======== //

  // ========== Tier 3 ========== //
  // Performance Product Detail
  performVisitDetail: [],
  performCartDetail: [],
  performOrderDetail: [],
  // Tren Penjualan Detail
  topCategoryDetail: [],
  topBrandDetail: [],
  // Promosi & Payment Detail
  topVoucherDetail: [],
  topPaymentDetail: [],
  memberDetail: [],
  // ======== End Tier 3 ======== //

});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_DETAIL(state, payload) {
    state.isLoadingDetail = payload;
  },
  SET_LOADING_TABLE(state, payload) {
    state.isLoadingTable = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_PAGE_DETAIL(state, payload) {
    state.pageDetail = payload;
  },
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  SET_PERFORMRATE_DATA(state, payload) {
    state.performRateData = payload;
  },
  SET_PERFORMVISIT_DATA(state, payload) {
    state.performVisitData = payload;
  },
  SET_PERFORMCART_DATA(state, payload) {
    state.performCartData = payload;
  },
  SET_PERFORMORDERS_DATA(state, payload) {
    state.performOrdersData = payload;
  },
  SET_TOPPAYMENT_DATA(state, payload) {
    state.topPaymentData = payload;
  },
  SET_TOPCATEGORY_DATA(state, payload) {
    state.topCategoryData = payload;
  },
  SET_TOPCATEGORY_OVERVIEW(state, payload) {
    state.topCategoryOverview = payload;
  },
  SET_TOPBRAND_DATA(state, payload) {
    state.topBrandData = payload;
  },
  SET_TOPBRAND_OVERVIEW(state, payload) {
    state.topBrandOverview = payload;
  },
  SET_TOPMEMBER_DATA(state, payload) {
    state.topMemberData = payload;
  },
  SET_TOPMEMBER_OVERVIEW(state, payload) {
    state.topMemberOverview = payload;
  },
  SET_INACTIVEMEMBER_DATA(state, payload) {
    state.InActiveMemberData = payload;
  },
  SET_INACTIVEMEMBER_OVERVIEW(state, payload) {
    state.InActiveMemberOverview = payload;
  },
  SET_TOPVOUCHER_DATA(state, payload) {
    state.topVoucherData = payload;
  },
  SET_TOPVOUCHER_OVERVIEW(state, payload) {
    state.topVoucherOverview = payload;
  },
  SET_TOPPAYMENT_DATA(state, payload) {
    state.topPaymentData = payload;
  },
  SET_TOPPAYMENT_OVERVIEW(state, payload) {
    state.topPaymentOverview = payload;
  },
  SET_OUTOFFSTOCK_DATA(state, payload) {
    state.outOffStockData = payload;
  },
  SET_ALMOSTOUTSTOCK_DATA(state, payload) {
    state.almostOutStockData = payload;
  },
  SET_MANAGEMENTMEMBER_DATA(state, payload) {
    state.managementMemberData = payload;
  },
  SET_PERFORMVISIT_DETAIL(state, payload) {
    state.performVisitDetail = payload;
  },
  SET_PERFORMCART_DETAIL(state, payload) {
    state.performCartDetail = payload;
  },
  SET_PERFORMORDER_DETAIL(state, payload) {
    state.performOrderDetail = payload;
  },
  SET_TOPCATEGORY_DETAIL(state, payload) {
    state.topCategoryDetail = payload;
  },
  SET_TOPBRAND_DETAIL(state, payload) {
    state.topBrandDetail = payload;
  },
  SET_TOPVOUCHER_DETAIL(state, payload) {
    state.topVoucherDetail = payload;
  },
  SET_TOPPAYMENT_DETAIL(state, payload) {
    state.topPaymentDetail = payload;
  },
  SET_MEMBER_DETAIL(state, payload) {
    state.memberDetail = payload;
  },
};

const actions = {
  // Tier 1
  getDashboardData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${PREFIX_1}`)
        .then((res) => {
          commit("SET_DASHBOARD_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  // Tier 2
  getPerformanceData({ commit, state, rootState, dispatch }, { payload, type }) {
    if (type === "rate") {
      commit("SET_LOADING", true);
    } else {
      commit("SET_LOADING_TABLE", true);
    }
    if (type) {
      return new Promise((resolve, reject) => {
        $axios
          .get(
            `${PREFIX_2}/performance-${type}?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
          )
          .then((res) => {
            if (type === "rate") {
              commit("SET_PERFORMRATE_DATA", res.data);
              commit("SET_LOADING", false);
            } else if (type === "visit") {
              commit("SET_PERFORMVISIT_DATA", res.data);
              commit("SET_LOADING_TABLE", false);
            } else if (type === "cart") {
              commit("SET_PERFORMCART_DATA", res.data);
              commit("SET_LOADING_TABLE", false);
            } else if (type === "orders") {
              commit("SET_PERFORMORDERS_DATA", res.data);
              commit("SET_LOADING_TABLE", false);
            }
            resolve(res.data);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            commit("SET_LOADING_TABLE", false);
            dispatch("Main/globalSweetAlert", error.response, {
              root: true,
            });
            reject(error);
          });
      });
    } else {
      dispatch("Main/globalSuccessSweetAlert", "Endpoint Salah", {
        root: true,
      });
      commit("SET_LOADING", false);
      commit("SET_LOADING_TABLE", false);
      return Promise.reject("Endpoint Salah");
    }
  },
  getTopCategoryData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/category?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPCATEGORY_DATA", res.data.data);
          commit("SET_TOPCATEGORY_OVERVIEW", res.data.top_product);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopBrandData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/brand?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPBRAND_DATA", res.data.data);
          commit("SET_TOPBRAND_OVERVIEW", res.data.top_product);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/customers-perform?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPMEMBER_DATA", res.data.data);
          commit("SET_TOPMEMBER_OVERVIEW", {
            countmember: res.data.countmember,
            countnonmember: res.data.countnonmember,
            totalmember: res.data.totalmember,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getInactiveMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/customers-notperform?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_INACTIVEMEMBER_DATA", res.data.data);
          commit("SET_INACTIVEMEMBER_OVERVIEW", {
            countmember: res.data.countmember,
            countnonmember: res.data.countnonmember,
            totalmember: res.data.totalmember,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopVoucherData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/promotions?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPVOUCHER_DATA", res.data.data);
          commit("SET_TOPVOUCHER_OVERVIEW", {
            count_order: res.data.count_order,
            count_member: res.data.count_member,
            count_transaction: res.data.count_transaction,
          });
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopPaymentData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/payment?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          const dataList = res.data.data;
          commit("SET_TOPPAYMENT_DATA", dataList);
          dataList.data.sort((a, b) => b.total_order - a.total_order);
          commit("SET_TOPPAYMENT_OVERVIEW", dataList.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getOutOfStockData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/oos?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_OUTOFFSTOCK_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getAlmostOutStockData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/almost-oos?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_ALMOSTOUTSTOCK_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getManagementMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_2}/vip-members?page=${state.page}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_MANAGEMENTMEMBER_DATA", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  // Tier 3
  getPerformanceVisitDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-visit?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMVISIT_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceCartDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-cart?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMCART_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getPerformanceOrderDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/performance-orders?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_PERFORMORDER_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopCategoryDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/category?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPCATEGORY_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopBrandDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/brand?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPBRAND_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopVoucherDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/promotions?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPVOUCHER_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getTopPaymentDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${PREFIX_3}/payment?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_TOPPAYMENT_DETAIL", res.data);
          commit("SET_LOADING_DETAIL", false);
        })
        .catch((error) => {
          commit("SET_LOADING_DETAIL", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getMemberDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `website/member/view?page=${state.pageDetail}&view=${rootState.Main.view}${payload || ""}`
        )
        .then((res) => {
          commit("SET_MEMBER_DETAIL", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

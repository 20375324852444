import $axios from "../../api";

const module = "dashboard"
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const state = () => ({
  isErrors: "",
  isSuccess: false,
  list: null,
  detail: null,
  isLoadingPipeline: false,
  isLoadingHired: false,
  isLoadingSource: false,
  isLoadingApplied: false,
  pipelineChartData: {},
  hiredChartData: {},
  sourceChartData: {},
  appliedChartData: {},
});

const mutations = {
  SET_LOADING(state, { key, status }) {
    state[key] = status;
  },
  SET_PIPELINE_CHART_DATA(state, data) {
    state.pipelineChartData = data;
  },
  SET_HIRED_CHART_DATA(state, data) {
    state.hiredChartData = data;
  },
  SET_SOURCE_CHART_DATA(state, data) {
    state.sourceChartData = data;
  },
  SET_APPLIED_CHART_DATA(state, data) {
    state.appliedChartData = data;
  },
  SET_ERROR(state, error) {
    state.isErrors = error;
  },
};

const actions = {
  getPipelineChart({ commit }) {
    commit("SET_LOADING", { key: "isLoadingPipeline", status: true });
    return $axios
      .get(`${module}/pipeline`)
      .then((res) => {
        commit("SET_PIPELINE_CHART_DATA", res.data);
        commit("SET_LOADING", { key: "isLoadingPipeline", status: false });
      })
      .catch((error) => {
        commit("SET_LOADING", { key: "isLoadingPipeline", status: false });
        commit("SET_ERROR", error.response);
      });
  },
  getHiredChart({ commit }) {
    commit("SET_LOADING", { key: "isLoadingHired", status: true });
    return $axios
      .get(`${module}/hired-rate`)
      .then((res) => {
        commit("SET_HIRED_CHART_DATA", res.data);
        commit("SET_LOADING", { key: "isLoadingHired", status: false });
      })
      .catch((error) => {
        commit("SET_LOADING", { key: "isLoadingHired", status: false });
        commit("SET_ERROR", error.response);
      });
  },
  getSourceChart({ commit }) {
    commit("SET_LOADING", { key: "isLoadingSource", status: true });
    return $axios
      .get(`${module}/source-insights`)
      .then((res) => {
        commit("SET_SOURCE_CHART_DATA", res.data);
        commit("SET_LOADING", { key: "isLoadingSource", status: false });
      })
      .catch((error) => {
        commit("SET_LOADING", { key: "isLoadingSource", status: false });
        commit("SET_ERROR", error.response);
      });
  },
  getAppliedChart({ commit }) {
    commit("SET_LOADING", { key: "isLoadingApplied", status: true });
    return $axios
      .get(`${module}/applied-positions`)
      .then((res) => {
        commit("SET_APPLIED_CHART_DATA", res.data);
        commit("SET_LOADING", { key: "isLoadingApplied", status: false });
      })
      .catch((error) => {
        commit("SET_LOADING", { key: "isLoadingApplied", status: false });
        commit("SET_ERROR", error.response);
      });
  },
};

const getters = {
  pipelineChartData: (state) => state.pipelineChartData,
  hiredChartData: (state) => state.hiredChartData,
  sourceChartData: (state) => state.sourceChartData,
  appliedChartData: (state) => state.appliedChartData,
  isLoadingPipeline: (state) => state.isLoadingPipeline,
  isLoadingHired: (state) => state.isLoadingHired,
  isLoadingSource: (state) => state.isLoadingSource,
  isLoadingApplied: (state) => state.isLoadingApplied,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

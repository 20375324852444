import $axios from "../../api";
import FileSaver from "file-saver";

// MODULE NAME
const module = "website/orders";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
  detail: null,
  orderSummary: 0,
  paymentNameList: [
    {
      id: 1,
      label: "Transfer",
    },
    {
      id: 2,
      label: "Cash on Delivery (COD)",
    },
    {
      id: 4,
      label: "Others",
    },
    {
      id: 5,
      label: "Credit Card/Debit Card",
    },
    {
      id: 6,
      label: "Virtual Account",
    },
    {
      id: 7,
      label: "Gopay",
    },
    {
      id: 8,
      label: "Shopeepay",
    },
    {
      id: 9,
      label: "Point",
    },
    {
      id: 10,
      label: "UOB EZPAY",
    },
    {
      id: 11,
      label: "QRIS Web",
    },
    {
      id: 12,
      label: "QRIS App",
    },
  ],
  courierNameList: [
    {
      id: 1,
      label: "JNE",
    },
    {
      id: 2,
      label: "SiCepat",
    },
    {
      id: 3,
      label: "Anteraja",
    },
    {
      id: 4,
      label: "Go Send",
    },
    {
      id: 5,
      label: "J&T",
    },
    {
      id: 6,
      label: "Lalamove",
    },
  ],
  OrderStatusList: [
    {
      id: 1,
      label: "Pending",
    },
    {
      id: 2,
      label: "Payment",
    },
    {
      id: 5,
      label: "Shipping",
    },
    {
      id: 3,
      label: "Complete",
    },
    {
      id: 4,
      label: "Incomplete",
    },
  ],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  ASSIGN_ORDER_SUMMARY(state, payload) {
    state.orderSummary = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/list?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("ASSIGN_ORDER_SUMMARY", res.data.summary);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  exportExcel({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/export-excel?view=${rootState.Main.view}${payload || ""
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileSaver.saveAs(
            res.data,
            `Order_view=${rootState.Main.view}${payload || ""
            }_${new Date()}.xlsx`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  printAWB({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(
          `${module}/print-awb`,
          {
            order_number: payload.order_number,
            expedition_id: payload.expedition_id,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileSaver.saveAs(
            res.data,
            `AWB_${payload.order_number}.pdf`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  printInvoice({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(
          `${module}/export-invoice`,
          {
            order_number: payload,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileSaver.saveAs(res.data, `Invoice_${payload}.pdf`);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  insertPayment({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/insert-payment`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  acceptPayment({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/accept-payment`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  readyToShip({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/ready-to-shipp`, { order_number: payload })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  inputWaybill({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/input-resi`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  addNote({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/insert-note`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  addFreeItem({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/add-free-item`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  cancelOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/cancel`, { order_number: payload })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  revertOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/revert`, { order_number: payload })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  updateAddress({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/update-address`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  sendEmailInvoice({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/resend-invoice`, {
          order_number: payload.order_number,
          email: payload.member.email,
        })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

import $axios from "../../../api";

// MODULE NAME
const module = "tiktok/category";
const assign ="tiktok/assign-category";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
  detail: null,
  assignlist: null,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_DETAIL(state, payload) {
    state.isLoadingDetail = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_ASSIGN_LIST(state, payload) {
    state.assignlist = payload;
  },
  ASSIGN_DETAIL(state, payload) {
    state.detail = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  create({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING_DETAIL", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
            `${module}/view?&id=${payload}`
        )
        .then((res) => {
            commit("ASSIGN_DETAIL", res.data.data);
            commit("SET_LOADING_DETAIL", false);
            resolve(res);
        })
        .catch((error) => {
            commit("SET_LOADING_DETAIL", false);
            dispatch("Main/globalSweetAlert", error.response, {
                root: true,
            });
        });
    });
  },
  update({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);

    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  showHide({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/showhide`, { id: payload })
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getAssignList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${assign}/?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_ASSIGN_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  createAssign({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${assign}/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
export default [
  // ================ dashboard tier 1 ================
  {
    name: "dashboard_index",
    path: "/dashboard",
    component: () => import("../pages/homepage/Dashboard.vue"),
  },
  {
    name: "dashweb_index",
    path: "/dashboard/website",
    component: () => import("../pages/homepage/DashboardWebsite.vue"),
  },
  {
    name: "dashapp_index",
    path: "/dashboard/app",
    component: () => import("../pages/homepage/DashboardApp.vue"),
  },
  {
    name: "dashtiktok_index",
    path: "/dashboard/tiktok",
    component: () => import("../pages/homepage/DashboardTiktok.vue"),
  },
  {
    name: "dashboard_indexvita",
    path: "/dashboard-vita",
    component: () => import("../pages/homepage/DashboardVita.vue"),
  },
  {
    name: "dashboard_indexhr",
    path: "/dashboard-hr",
    component: () => import("../pages/homepage/DashboardHr.vue"),
  },
  {
    name: "dashboard_indexreseller",
    path: "/dashboard-reseller",
    component: () => import("../pages/homepage/DashboardReseller.vue"),
  },
  {
    name: "dashboard_indexit",
    path: "/dashboard-it",
    component: () => import("../pages/homepage/DashboardIT.vue"),
  },
  {
    name: "dashboard_indexexternal",
    path: "/dashboard-external",
    component: () => import("../pages/homepage/DashboardExternal.vue"),
  },
  // ================ dashboard tier 2 ================ 
  {
    name: "dashweb_orders",
    path: "/dashboard/website/performance-orders",
    component: () => import("../pages/homepage/website/performanceProduct.vue"),
  },
  {
    name: "dashweb_cart",
    path: "/dashboard/website/performance-cart",
    component: () => import("../pages/homepage/website/performanceProduct.vue"),
  },
  {
    name: "dashweb_visit",
    path: "/dashboard/website/performance-visit",
    component: () => import("../pages/homepage/website/performanceProduct.vue"),
  },
  {
    name: "dashweb_brand",
    path: "/dashboard/website/brand",
    component: () => import("../pages/homepage/website/performanceBrand.vue"),
  },
  {
    name: "dashweb_category",
    path: "/dashboard/website/category",
    component: () => import("../pages/homepage/website/performanceCategory.vue"),
  },
  {
    name: "dashweb_custperform",
    path: "/dashboard/website/customers-perform",
    component: () => import("../pages/homepage/website/performanceMember.vue"),
  },
  {
    name: "dashweb_custnotperform",
    path: "/dashboard/website/customers-notperform",
    component: () => import("../pages/homepage/website/performanceNonActiveMember.vue"),
  },
  {
    name: "dashweb_promotions",
    path: "/dashboard/website/promotions",
    component: () => import("../pages/homepage/website/voucherPromo.vue"),
  },
  {
    name: "dashweb_payment",
    path: "/dashboard/website/payment",
    component: () => import("../pages/homepage/website/paymentMethod.vue"),
  },
  {
    name: "dashweb_oos",
    path: "/dashboard/website/oos",
    component: () => import("../pages/homepage/website/outOffStock.vue"),
  },
  {
    name: "dashweb_aoos",
    path: "/dashboard/website/almost-oos",
    component: () => import("../pages/homepage/website/lowStock.vue"),
  },
  {
    name: "dashweb_vipmembers",
    path: "/dashboard/website/vip-members",
    component: () => import("../pages/homepage/website/vipManagement.vue"),
  },

  // ================ dashboard tier 2 App ================ 
  {
    name: "dashapp_orders",
    path: "/dashboard/app/performance-orders",
    component: () => import("../pages/homepage/app/performanceProduct.vue"),
  },
  {
    name: "dashapp_cart",
    path: "/dashboard/app/performance-cart",
    component: () => import("../pages/homepage/app/performanceProduct.vue"),
  },
  {
    name: "dashapp_visit",
    path: "/dashboard/app/performance-visit",
    component: () => import("../pages/homepage/app/performanceProduct.vue"),
  },
  {
    name: "dashapp_brand",
    path: "/dashboard/app/brand",
    component: () => import("../pages/homepage/app/performanceBrand.vue"),
  },
  {
    name: "dashapp_category",
    path: "/dashboard/app/category",
    component: () => import("../pages/homepage/app/performanceCategory.vue"),
  },
  {
    name: "dashapp_custperform",
    path: "/dashboard/app/customers-perform",
    component: () => import("../pages/homepage/app/performanceMember.vue"),
  },
  {
    name: "dashapp_custnotperform",
    path: "/dashboard/app/customers-notperform",
    component: () => import("../pages/homepage/app/performanceNonActiveMember.vue"),
  },
  {
    name: "dashapp_promotions",
    path: "/dashboard/app/promotions",
    component: () => import("../pages/homepage/app/voucherPromo.vue"),
  },
  {
    name: "dashapp_payment",
    path: "/dashboard/app/payment",
    component: () => import("../pages/homepage/app/paymentMethod.vue"),
  },
  {
    name: "dashapp_oos",
    path: "/dashboard/app/oos",
    component: () => import("../pages/homepage/app/outOffStock.vue"),
  },
  {
    name: "dashapp_aoos",
    path: "/dashboard/app/almost-oos",
    component: () => import("../pages/homepage/app/lowStock.vue"),
  },
  {
    name: "dashapp_vipmembers",
    path: "/dashboard/app/vip-members",
    component: () => import("../pages/homepage/app/vipManagement.vue"),
  },

  // ================ dashboard tier 2 Tiktok ================ 
  {
    name: "dashtiktok_product",
    path: "/dashboard/tiktok/product",
    component: () => import("../pages/homepage/tiktok/performanceProduct.vue"),
  },
  {
    name: "dashtiktok_brand",
    path: "/dashboard/tiktok/brand",
    component: () => import("../pages/homepage/tiktok/performanceBrand.vue"),
  },
  {
    name: "dashtiktok_category",
    path: "/dashboard/tiktok/category",
    component: () => import("../pages/homepage/tiktok/performanceCategory.vue"),
  },
  {
    name: "dashtiktok_custperform",
    path: "/dashboard/tiktok/customers-perform",
    component: () => import("../pages/homepage/tiktok/performanceMember.vue"),
  },
  {
    name: "dashtiktok_custnotperform",
    path: "/dashboard/tiktok/customers-notperform",
    component: () => import("../pages/homepage/tiktok/performanceNonActiveMember.vue"),
  },
  {
    name: "dashtiktok_promotions",
    path: "/dashboard/tiktok/promotions",
    component: () => import("../pages/homepage/tiktok/voucherPromo.vue"),
  },
  {
    name: "dashtiktok_payment",
    path: "/dashboard/tiktok/payment",
    component: () => import("../pages/homepage/tiktok/paymentMethod.vue"),
  },
];
import $axios from "../../api";
const state = () => ({
  isLoading: false,
  list: [],
});
const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_DATA(state, payload) {
    state.list = payload;
  },
};

const actions = {
  getData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post('/server/vscode/performance', payload)
        .then((res) => {
          commit("ASSIGN_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

